.Selector {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  font-size: medium;

  div {
    margin-right: 5px;
    padding-top: 4px;
    padding: 5px 5px 0px 5px;
  }

  .selection {
    cursor: pointer;
  }

  .selection.selected {
    font-weight: bold;
    border: black solid 1px;
    border-radius: 5px;
  }
}
