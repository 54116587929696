$primary-background: #679BEF;
body {
  min-height: 100vh;
}

#root {
  min-height: 100vh;
}

.App {
  user-select: none;
  display: flex;
  flex-direction: column;
  min-height: 98vh;

  .TopNavigation {
    height: 45px;
    margin-bottom: 5px;
    background-color: $primary-background;
    flex-grow: 0;
  }

  .main-content {
    padding: 20px;
    flex-grow: 2;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .guided {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      button {
        border-radius: 8px;
        color: #277027;
        background-color: #e7fadb;
        font-size: x-large;
        font-weight: bold;
        padding: 10px;
        margin-left: 40px;
        cursor: pointer;
      }

      button:hover {
        background-color: #f2fdeb;

      }
    }

    .label {
      font-weight: bold;
      margin-bottom: 10px;
    }

    .chart-label {
      font-weight: bold;
    }

    .button-bar {
      margin-top: 30px;
      button {
        margin-right: 10px;
      }
    }
  }

  .explanation {
    margin-top: 5px;
    font-size: small;
  }

  .BottomNavigation {
    margin-top: auto;
    background-color: $primary-background;
    flex-grow: 0;
  }

  button {
    padding: 5px 5px 5px 5px;
  }

  #firebaseui-auth-container {
    button {
      padding: 0px;
    }
  }
}
