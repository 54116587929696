.DrillSegmentsChart {
  position: relative;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: larger;

  .measurement-section {
    cursor: pointer;
  }

  .measurement-section.focused {
    border-bottom: black 2px solid;
  }

}