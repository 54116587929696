.TopNavigation {
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .page-title {
    font-weight: bold;
    font-size: 18px;
    color: black;
    text-decoration: none;
  }

  .page-sub-title {
    font-weight: bold;
    font-size: 12px;
  }

  .right-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-right: 5px;
    width: 100%;

    div.user-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      margin-left: 20px;
    }
  }

    span.settings-toggle {
      color: #e3e8f1;
      cursor: pointer;
      margin: 0 10px;
      font-size: xx-large;
    }

    span.settings-toggle:hover {
      color: #ffffff;
    }
}