.TypeOverSection {
  margin-bottom: 10px;
  font-family: monospace;

  .target {
    color: gray;
    background-color: white;
    border-bottom: rgba(255,255,255,0) 2px solid;
    border-left: rgba(255,255,255,0) 2px solid;
  }

  .typed {
    color: black;
    background-color: white;
    border-bottom: rgba(255,255,255,0) 2px solid;
    border-left: rgba(255,255,255,0) 2px solid;
  }
}

.TypeOverSection.current {
  .target {
    border-bottom: gray 2px solid;
    border-left: black 2px solid;
  }
}

.TypeOverSection.has-error {
  .typed {
    background-color: #f7baba;
  }
}

.SimpleTypingGame.normal {
  .TypeOverSection {
    font-size: 24px;
  }
}

.SimpleTypingGame.focused {
  .TypeOverSection {
    opacity: 40%;
    font-size: 52px;
  }

  .TypeOverSection.current {
    opacity: 100%;
  }
}

.SimpleTypingGame.zen {
  .TypeOverSection {
    display: none;
    font-size: 70px;
  }

  .TypeOverSection.current {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}