.CustomGenerator {
  display: flex;

  textarea {
    height: 50px;
    width: 200px;
  }

  input {
    margin-left: 5px;
    margin-right: 5px;
  }

  input.goal {
    width: 50px;
  }
}