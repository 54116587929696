.GeneratorEditor {
  margin-top: 10px;
  display: flex;
  flex-direction: row;

  select {
    margin-bottom: 8px;
  }

  .details {
    margin-left: 30px;

    .cell {
      margin-right: 20px;
    }
  }
}