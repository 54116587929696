.GoalEditor {
  margin-top: 20px;

  input.goal {
    margin-left: 5px;
    margin-right: 5px;
    width: 45px;
  }

  input.goal.accuracy {
    width: 40px;
  }
}