$cell-border-color: #d2d0d0;

.DrillDetailChart {
  position: relative;
  width: 100%;

  .press-detail {
    position: absolute;
    height: 18px;
    font-family: "monospace";
    z-index: 100;
    cursor: pointer;
  }

  .press-detail.focused {
    font-weight: bold;
    border-bottom: black 2px solid;
  }

  .row-div {
    position: absolute;
    left: 0px;
    width: 100%;
    border-top:  $cell-border-color solid thin;
    border-bottom: $cell-border-color solid thin;
    z-index: 50;
  }

  .column-div {
    position: absolute;
    top: 0px;
    border-left: $cell-border-color solid thin;
    border-right: $cell-border-color solid thin;
    z-index: 50;
  }
}