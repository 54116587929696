.SectionSummary {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  .details {
    font-size: medium;
    margin-right: 30px;
    div {
      margin-bottom: 4px;
    }
  }

  .main {
    margin-right: 30px;
    font-size: xx-large;
    align-self: center;
  }
}
