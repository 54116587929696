@import "../../../colors";

.DrillSummary {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

  .details {
    font-size: medium;
    margin-right: 30px;
    div {
      margin-bottom: 10px;
    }
  }

  .main {
    margin-right: 30px;
    font-size: xxx-large;
    font-weight: bold;
    align-self: center;
  }

  .accuracy {
    color: $dark-green;
  }
}