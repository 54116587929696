.GoalSummary {
  margin-bottom: 10px;

  .label {
    font-size: xxx-large;
    margin-right: 20px;
  }

  .goal-sentence {
    .value {
      font-size: xxx-large;
      font-weight: bold;
    }
  }
}