.ModalContent {
    position: fixed;
    z-index: 2000;
    top: 70px;
    left: 20px;
    margin-right: 25px;
    border: black solid 3px;
    border-radius: 8px;
    background-color: #F2EBD5;

    .static-content-title:first-of-type {
        margin-top: -27px;
    }

    .modal-content-top {
        display: flex;
        flex-direction: row;
        margin-bottom: 5px;
        margin-top: 5px;
        font-weight: bold;

        .modal-content-title {
            margin-right: 10px;
        }

        .modal-content-closer {
            margin-left: auto;
            margin-right: 10px;
            margin-top: 5px;
            padding: 3px;
            border: black solid 1px;
            border-radius: 4px;
        }

        .modal-content-closer:hover {
            background-color: #E3C152;
        }
    }
}