@import "../../../colors";

.DrillMeasurementTrendChart {
  width: 100%;
  height: 200px;
  position: relative;
  display: flex;
  flex-direction: row;
  border: black solid thin;
  margin-top: 10px;
  overflow: hidden;

  .measurement-div {
    position: absolute;
    background-color: $dark-green;
    cursor: pointer;
    z-index: 1;
  }

  .measurement-border {
    position: absolute;
    outline: black solid 2px;
    z-index: 30;
  }

  .goal-div {
    position: absolute;
    border-top: #f3bc33 solid 3px;
    z-index: 10;
  }

  .shown-line {
    position: absolute;
    z-index: 50;
    left: 0%;
    height: 3px;
    background-color: green;
  }
}