.Confetti {
  position: absolute;
  bottom: 0%;
  height: 100%;
  left: 0%;
  width: 100%;

  .piece {
    position: relative;
    width: 10px;
    height: 5px;
  }
}