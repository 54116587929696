.LoadingIndicator {
  position: absolute;
  top: 45vh;
  left: 45vw;

  .loading-label {
    font-weight: bold;
    font-size: x-large;
    margin-bottom: 10px;
  }
}
