.SimpleTypingGame {
  /* do not show the focus outline */
  outline: none;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 30px;

  padding: 20px;

  .typing {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  input {
    position: absolute;
    left: -100px;
    width: 0px;
  }

  .escape-message {
    font-size: small;
    margin-top: 20px;
  }
}