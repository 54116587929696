.PatternGeneratorEditor {
  display: flex;
  flex-direction: row;

  input.length-input {
    width: 34px;
  }

  span {
    padding-left: 5px;
    padding-right: 5px;
  }

  .common-only {
    input {
      margin-top: 10px;
    }
  }



}